
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-12" style="padding-left: 50px;">
        <b-card class="card-icon  mb-30" style="padding-left: 50px;">
          <h3>Reports</h3>
          <hr/>
          <div class="row" >
            <div class="col-md-4">
              <ul>
                <li>
                  <a style="text-decoration: underline;" href="/reports/accrual">Accrual Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/redemption">Overall Redemption Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/parkingRedemption">Parking Redemption Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/parkingVouchersRedemption">Parking Vouchers Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/reversedTransaction">Reversed transactions Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/pendingTransaction">Pending transactions Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/users">Users Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/customers">Customers Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/newRegistration">New Registrations Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/bonusPointsReport">Bonus Points Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/voucherUsageReport">Voucher Usage Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="#" @click="openEpurseReportModal">Epurse Report</a>
                </li>
              </ul>


            </div>
          </div>

        </b-card>
      </div>
    </div>

    <b-modal id="export-epurse" title="Export epurse report" @cancel="true" @ok="exportToExcel"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Please enter your email address:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="username" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  data() {
    return {
      disabled:false,
      isLoading: false,
      fullPage: true,
      username:""
    };
  },
  components: {
    Loading,
    DatePicker
  },
  mounted (){

  },
  methods: {
    ...mapActions(["exportEpurseReport"]),

    openEpurseReportModal() {
      this.$bvModal.show('export-epurse');
    },
    exportToExcel(){
      let self = this;

      this.isLoading = true;

      this.exportEpurseReport({username: this.username})
        .then(function () {
          self.isLoading = false;

          self.$bvToast.toast("Export request received. We'll email you the report once its ready", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });
        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

        })
    },

  }
};
</script>
